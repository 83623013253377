@keyframes err{
  0%,
  100%{
    transform: translateX(0);
 }

  12.5%{
    transform: translateX(12px);
 }

  25%{
    transform: translateX(-12px);
 }

  37.5%{
    transform: translateX(8px);
 }

  50%{
    transform: translateX(-8px);
 }

  62.5%{
    transform: translateX(5px);
 }

  75%{
    transform: translateX(-5px);
 }

  87.5%{
    transform: translateX(3px);
 }
}

@keyframes spin{
  0%{
    transform: rotate(0);
 }
  100%{
    transform: rotate(360deg);
 }
}

@keyframes spinBanner{
  0%{
    transform: translateX(-50%) rotate(0);
 }
  100%{
    transform: translateX(-50%) rotate(360deg);
 }
}

@keyframes inputIn{
  0%{
    display: block;
  }
  100%{
    opacity: 1;display: block;font-size: 11px;top: 4px;transform: translate(0,2px);
  }
}

@keyframes inputOut{
  0%{
    opacity: 1;display: block;font-size: 11px;top: 4px;transform: translate(0,2px);
  }
  99%{
    display: block;
  }
  100%{
    font-size: 12px;top: 50%;transform: translate(-3px,calc(-50% - 1px));opacity: 0;display: none;
  }
}

@keyframes upTitleBox{
  from {
    z-index: 3;
  }

  to {
    z-index: 1;
  }
}

@keyframes filpUp{
  0% {
    transform: rotateX(0deg);
  }

  25% {
    transform: rotateX(-90deg);
  }

  100% {
    transform: rotateX(-90deg);
  }
}

@keyframes filpDown{
  0% {
    transform: rotateX(90deg);
  }

  25% {
    transform: rotateX(0deg);
  }

  100% {
    transform: rotateX(0deg);
  }
}

@keyframes loadingSpin{
  0%{
    transform: rotate(0);
  }
  20%{
    transform: rotate(195deg);
  }
  22.5%{
    transform: rotate(165deg);
  }
  25%{
    transform: rotate(180deg);
  }
  35%{
    transform: rotate(180deg);
  }
  55%{
    transform: rotate(375deg);
  }
  58.5%{
    transform: rotate(345deg);
  }
  60%{
    transform: rotate(360deg);
  }
  100%{
    transform: rotate(360deg);
  }
}

@keyframes skeletonUI{
  0% {
    opacity: 1
  }

  25% {
    opacity: 0.6
  }

  100% {
    opacity: 1
  }
}

@keyframes pcText{
  0% {
    transform: translateY(-339.2px);
  }

  2% {
    transform: translateY(-254.4px);
  }

  25% {
    transform: translateY(-254.4px);
  }

  27% {
    transform: translateY(-169.6px);
  }

  50% {
    transform: translateY(-169.6px);
  }

  52% {
    transform: translateY(-84.8px);
  }

  75% {
    transform: translateY(-84.8px);
  }

  77% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0);
  }
}

.gTextColor{color:var(--mainColor) !important}
.oTextColor{color:#ED6F4E !important}
.boldText{font-weight: 700 !important}
.backgroundImg{background-repeat: no-repeat;background-position: center;background-size: cover}

.rTextColor{color:var(--rTextColor) !important}
.rBg{background-color:var(--rBgColor) !important}

.skeletonUI{animation: skeletonUI 2s infinite;background-color: #F1F2F5}
.textItem{width:30px;height: 13px;display: block;margin: 0 !important;border-radius: 6px}
.textItemCenter{margin: 0 auto !important}
.textItemFull{width: 100%}
.textItemFull + .textItemFull{margin: 10px auto 0 !important}

.scrollCover{position: absolute;left: 0;top: 0;width: 100%;height: 100%;z-index: 30}
.scrollCoverBox{position: relative}

/*header*/
.header{position: fixed;top: 0;left: 0;width: 100%;height: 58px;z-index: 1000;transition: top 0.2s}
.header.hide{top: -59px}
.headerSection{height: 100%;padding: 0 20px;position: relative;background: #fff}
.headerSection.notBg{background: transparent}
.btnHeaderRight{position: absolute;right: 20px;top: 50%;transform: translateY(-50%)}
.btnHeaderRightItem{display: block;color: var(--mainTextColor);text-decoration: underline;font-size: 13px;font-weight: 600}
.btnHeaderLeft{position: absolute;left: 20px;top: 50%;transform: translateY(-50%)}
.headTitle{font-size: 13px;font-weight: 600;padding: 0 35px;display: flex;width: 100%;height: 54px;align-items: center;gap: 4px}
.headTitle.centerTitle{text-align: center;justify-content: center}
.btnHeaderRightLink{font-size: 13px;font-weight: 300;color: #ACACAC;text-decoration: underline;text-align: right}

.btnAlarmLinkItem{position: relative;}
.btnAlarmLink.active .btnAlarmLinkItem::before{content: "";background: #FB4D44;border: 2px solid #fff;width: 9px;height: 9px;position: absolute;top: 0;right: 0;border-radius: 100%}
.btnShLink{width: calc(100% - 23px - 11px - 40px);background: #F1F2F5;border-radius: 6px;text-align: left;display: flex;align-items: center;justify-content: space-between;gap: 15px;padding: 10px 12px;color: #9D9FA2;font-size: 13px;font-weight: 500;}

/*editerBox*/
.editerBox *{font-weight: revert;color: revert;font-family: revert;font-size: revert;line-height: revert;white-space: pre-wrap}
.editerBox img{max-width: 100%}

/*bottomErrMsg*/
.bottomErrMsg{position: fixed;bottom: 100px;transform: translateX(-50%);max-width: 400px;text-align: center;padding: 9px 18px;color: var(--mainTextColor);font-size: 13px;font-weight: 500;opacity: 0;background: rgba(58, 58, 58, 0.8);border-radius: 8px;left: 50%;width: calc(100% - 40px);display: none;transition: opacity 0.2s;z-index: 9999}
.bottomErrMsg.active{opacity: 1}

/*loarding*/
.loadingBox{position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 9999;display: none}
.loadingBox.active .popup_cover{transition: opacity 0s;opacity: 1}
.loading{position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%)}
.loadingItem{max-width: 25px;display: flex;align-items: center;justify-content: center;}
.loadingItem>img{width: 100%;display: block;animation: loadingSpin 1.2s infinite;max-width: 25px}
.loadingBox .loadingItem{background: #fff;position: fixed;top: 50%;left: 50%;max-width: 100%;width: 70px;height: 70px;transform: translate(-50%, -50%);border-radius: 100%;box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.1);}

.loadingItem.reloadLoading{opacity: 0}
.loadingItem.reloadLoading.active{opacity: 1}
.reloadLoadingBox{position: absolute;top: 50px;left: 50%;margin-left: -12.5px}

/*err*/
.pageErrBox{text-align: center;padding: 70px 20px}
.pageErrTitle{font-size: 22px;font-weight: 700}
.pageErrText{margin: 14px 0 0;font-size: 14px}

/*page*/
.pageTitleBox{width: 100%;padding: 20px 20px 30px}
.pageTitle{color: var(--mainTextColor);font-size: 20px;font-weight: 600}
.pageSubTitle{font-weight: 500;margin: 4px 0 0;color: var(--mainTextColor);font-size: 13px}
.pageSubTitleBtn{color: var(--mainTextColor);font-size: 13px;font-weight: 500;margin: 14px 0 0;text-decoration: underline}

/*contentsSection*/
.contentsSection{position: relative}
.contentsSection.showChkContents{transition: padding-top 0.2s}
.contentsSection.bottomContents{display: flex;align-items: center;flex-direction: column;justify-content: space-between;min-height: calc(var(--vh, 1vh) * 100)}
.contentsSection.headerContents{padding-top: 54px}
.contentsSection.btnContents{padding-bottom: 55px}
.contentsSection.footerContents{padding-bottom: 60px}
.contentsSection.headerTapContents{padding-top: 38px}
.contentsSection.headerTapContents.headerContents{padding-top: calc(38px + 58px)}
.contentsSection.bottomBasicPadding{padding-bottom: 50px}

.itemSection{padding: 20px}
.itemSection + .itemSection{border-top: 6px solid #303036}
.itemSection.notPaddingTop{padding-top: 0}
.itemSection.notPaddingSide{padding-left: 0;padding-right: 0}
.itemSection + .itemSection.minLine{border-width: 1px}

/*tap*/
.tapBox{height: 100%;display: flex;align-items: center;gap: 14px;overflow-x: auto}
.tapItem{height: 100%;white-space: nowrap;font-size: 14px;color: #77797C;font-weight: 400;border-bottom: 2px solid transparent}
.oColor .tapItem.active{border-color: rgb(237, 111, 78);color: rgb(237, 111, 78)}
.tapItem.active{font-weight: 700;border-color: #000;color: #000}
.tapBox.heightSet{height: 44px}

.tapBox.showChkTap{transition: top 0.2s}
.tapBox.headerComTap{width: 100%;position: fixed;top: 58px;left: 0;background: #fff;height: 38px;gap: 0;overflow-x: visible;border-bottom: 1px solid #E5E6EB;z-index: 1000}
.tapBox.fixedTap{width: 100%;position: fixed;top: 0;left: 0;background: #fff;height: 38px;gap: 0;overflow-x: visible;border-bottom: 1px solid #E5E6EB;z-index: 1000}
.tapBox.headerComTap .tapItem,.tapBox.fixedTap .tapItem{padding: 0 10px;width:100%}
.tapBox.headerComTap .tapItem:first-child:nth-last-child(2),.tapBox.headerComTap .tapItem:first-child:nth-last-child(2) ~ .tapItem{width: 50%}
.tapBox.headerComTap .tapItem:first-child:nth-last-child(3),.tapBox.headerComTap .tapItem:first-child:nth-last-child(3) ~ .tapItem{width: 33.33%}
.tapBox.headerComTap .tapItem:first-child:nth-last-child(4),.tapBox.headerComTap .tapItem:first-child:nth-last-child(4) ~ .tapItem{width: 25%}
.tapBox.fixedTap .tapItem:first-child:nth-last-child(2),.tapBox.fixedTap .tapItem:first-child:nth-last-child(2) ~ .tapItem{width: 50%}
.tapBox.fixedTap .tapItem:first-child:nth-last-child(3),.tapBox.fixedTap .tapItem:first-child:nth-last-child(3) ~ .tapItem{width: 33.33%}
.tapBox.fixedTap .tapItem:first-child:nth-last-child(4),.tapBox.fixedTap .tapItem:first-child:nth-last-child(4) ~ .tapItem{width: 25%}

.subTapBox{display: flex;align-items: center;gap: 4px;overflow-x: auto;padding: 15px 20px}
.subTapItem{border-radius: 100px;color: #6E6E77;font-size: 13px;padding: 7px 16px;white-space: nowrap}
.subTapItem.active{background: #303036;font-weight: 600;color: var(--mainTextColor)}

.popupTapBox{width: calc(100% - 30px);display: flex;align-items: center;justify-content: flex-start;gap: 14px;}
.popupTapItem{font-size: 16px;font-weight: 700;color: #6E6E77;}
.popupTapItem.active{color: var(--mainTextColor);}

/*notItem*/
.notItemArea{display: flex;flex-direction: column;align-items: center;justify-content: center;gap: 8px;text-align: center;height: 100%;width: 100%}
.notItemArea.min{height:auto;padding: 30px 0}
.notItemArea.fullPage{min-height: 60vh}
.notItemText{font-size: 13px;font-weight: 500;color: var(--disabledColor)}

/*subLine*/
.subLine{display: block;height: 6px;background: #F3F3F3;width:100%}
.subLine.pageFull{width: 100vw;transform: translateX(-20px)}

/*category*/
.categoryBox{width: 100vw;margin: 15px 0 15px -20px;padding: 0 20px;display: flex;overflow-x: auto}
.categoryItem{padding: 3px 8px;white-space: nowrap;display: inline-block;color: #ACACAC;font-size: 13px;font-weight: 700;border-radius: 43px}
.categoryItem.active{background: #FF7AB7;color: var(--mainTextColor);font-weight: 800}

/*accordion*/
.accordionTitleBox{width: calc(100% - 40px);margin: 0 auto;border-bottom: 1px solid #303036;align-items: center;padding: 20px 0;display: flex}
.accordionBox.active .accordionTitleBox{border: 0}
.accordionIcon{color: #6E6E77;font-size: 14px;font-weight: 700;min-width: 14px;text-align: left;line-height: 1.8}
.accordionBox.active .questionIcon{color: var(--mainTextColor)}
.accordionTextBox{display: flex;align-items: flex-start;width: calc(100% - 5px - 11px);padding-right: 16px}
.accordionTitle{text-align: left;width: 100%;font-size: 14px;font-weight: 600;line-height: 1.8}
.accordionTextBox + img{min-width: 11px;transition: transform 0.2s,opacity 0.2s;opacity: 0.5}
.accordionBox.active .accordionTextBox + img{transform: rotate(180deg);opacity: 1}
.answerText{background: #222225;padding: 20px 20px 30px}
.accordionText{font-size: 13px;line-height: 20px;color: rgba(255, 255, 255, 0.9)}
.answerText .accordionTextBox{padding: 0;width: 100%}

/*input*/
.input_section{padding: 0 20px 50px;width: 100%}

.inputItemBox{margin: 20px 0 0;position: relative}
.inputItemBox:first-child{margin-top: 0}
.inputItemBox.marginUp{margin: 30px 0 0}
.inputName{animation: inputOut 0.1s forwards;position: absolute;z-index: 1;left: 12px;top: 50%;transform: translate(0,calc(-50% - 1px));font-size: 14px;color: #909AA4;opacity: 0}
.inputItemBox.active .inputName{animation: inputIn 0.1s forwards}
.inputItem{box-shadow: 0 1px 5px 0 rgba(0,0,0,0.1);border: 1px solid transparent;border-radius: 10px;width: 100%;padding: 13px 12px;font-size: 14px;color: var(--mainTextColor)}
.inputItemBox.active .inputItem{padding: 19px 12px 7px}
.inputItemBox.active .notName .inputItem{padding: 13px 15px}
.err .inputItem{animation: err 0.3s;border-color: #F44D4D}
.selItem .inputItem{color: #1E1E1E;background: url(/assets/images/basic/sel_icon.svg) no-repeat center right 0;background-color: var(--mainTextColor);padding-right: 40px}
.selItem .inputItem:disabled{color: #838383;background-color: rgba(0,0,0,0.1)}
.inputSet.pwBox .inputItem{padding-right: 50px}
.btn_pwCh{position: absolute;right: 8px;top: 50%;transform: translateY(-50%)}
.inputComBox{position: relative}
.inputComBox .inputItem{padding-right: 90px}
.btn_inputSubBtn{min-width: 67px;min-height: 26px;background: #c7c7c7;border-radius: 49px;color: var(--mainTextColor);font-size: 13px;padding: 4px;font-weight: 700;position: absolute;right: 13px;top: 50%;transform: translateY(-50%)}
.btn_inputSubBtn.pBg{background: #FF7AB7}
.cetifiedTimer{font-size: 13px;font-weight: 500;color: var(--mainTextColor);position: absolute;right: 15px;top: 50%;transform: translateY(-50%)}
.cetifiedInput .inputItem{padding-right: 122px}
.errMsg{color: #F04C41;margin: 4px 0 0;font-size: 12px;font-weight: 500}
.inputMsgBox .errMsg{margin: 0}
.inputMsgBox{margin: 4px 0 0;display: flex;gap: 10px;justify-content: space-between}
.inputMsgBox.contentEnd{justify-content: flex-end}
.btn_inputSubItem{font-size: 12px;font-weight: 500;color: var(--mainTextColor);text-decoration: underline;text-align: right}
.inputCaption{margin: 4px 0 0;font-size: 12px;color: rgba(255, 255, 255, 0.6);font-weight: 500}
.inputSet{position: relative}
.notReadOnlyColor .inputItem{color: #1e1e1e;background: #fff}

.inputUnitComBox .inputItem{padding-right: 85px}
.inputUnit{font-size: 15px;font-weight: 700;color: #5D5D5D;position: absolute;top: 50%;transform: translateY(-50%);right: 15px}

.inputBox.selectIn .inputItem{padding-left: 115px}
.inputBox.selectIn .inputItem + .inputItemBox{width: 90px;margin: 0;position: absolute;top: 50%;left: 15px;transform: translateY(-50%)}
.inputItemBox.active .inputBox.selectIn .inputItem + .inputItemBox{transform: translateY(calc(-50% + 7px))}
.inputBox.selectInItem .inputItem{font-size: 11px;padding: 2px 6px !important;border-radius: 5px;background-color: #6E6E77;border-color: #6E6E77;color: var(--mainTextColor);display: block}
.inputBox.selectInItem .selItem .inputItem{background-size: 20px;background-position: center right 5px}
.inputBox.selectInItem .selItem .inputItem::placeholder{color:#fff}

.chk_item{padding: 2px 0;display: flex;align-items: center;justify-content: space-between;gap: 15px}
.chk_item:not(.allChkItem) + .chk_item{margin: 16px 0 0}
.chk_item.allChkItem{margin: 0 0 20px}
.chk_item.allChkItem .chk_item_text{font-weight: 600;font-size: 15px}
.chk_item label{position: relative;padding: 0 0 0 30px;width: calc(100% - 34px);font-weight: 700}
.chk_item label::before{content: "";position: absolute;left: 0;top: 50%;transform: translateY(-50%);width: 20px;height: 20px;background: url(/assets/images/basic/chk_off.svg) no-repeat center;background-size: 100%}    
.chk_item input:checked + label::before{background-image: url(/assets/images/basic/chk_on.svg)}
.chk_item_text{color: var(--mainTextColor);font-size: 13px}
.chkBoxBtn{color:#6E6E77;text-decoration: underline;font-size: 13px;text-align: right;white-space: nowrap}
.inputItemBox + .chk_item{margin-top: 16px}

.textareaItem{display: block;resize: none;min-height: 180px;border: 1px solid transparent;border-radius: 10px;width: 100%;padding: 12px 15px;font-size: 14px;color: var(--mainTextColor)}
.textareaCount{font-size: 13px;width: 100%;text-align: right;padding: 8px 0 0;color: #ACACAC}
.textAreaBtnComBox{position: relative}
.textAreaBtnComBox .textareaItem{background: #fff;color: #1E1E1E;padding-right: 90px}
.textAreaBtnComBox .btn_inputSubBtn{top: 14px;transform: translateY(0)}

.inputFileList{width: 100vw;transform: translateX(-20px);padding: 0 20px;overflow-x: auto;display: flex;gap: 12px}
.inputFileBox .inputFileList label{min-width: 70px;width: 70px;height: 70px;padding: 0;border-radius: 14px;display: flex;align-items: center;justify-content: center;background: #303036}
.fileItemMultipleItem{background-repeat: no-repeat;background-size: cover;background-position: center;width: 70px;height: 70px;min-width: 70px;border-radius: 14px;position: relative}
.fileItemMultipleItemDel{position: absolute;top:5px;right:5px;z-index: 1}

.react-datepicker-wrapper{width: 100%}
.datePicerInputBox .inputItem{width: 100%;background: url(/assets/images/basic/cal.svg) no-repeat right 15px center;background-color: var(--mainTextColor);color: #1E1E1E}

.inputSubCaptionItem{position: relative}
.inputSubCaptionItem .inputItem{padding-left: 33px}
.inputSubCaption_text{font-size: 15px;position: absolute;left: 15px;top: 50%;transform: translateY(-50%)}

.customSelectInputBox{position: relative}
.customSelectInputBox.active::after{transform: translateY(-50%) rotate(180deg)}
.customSelectInputBox::after{content: "";width:11px;height:6px;position: absolute;background: url(/assets/images/basic/sel.svg) no-repeat center;top: 50%;transform: translateY(-50%);right: 12px}
.customSelectInputBox .inputItem{background: #303036;color: var(--mainTextColor);padding-right: 34px}
.customSelect_optionBox{border: 1px solid #424243;border-radius: 10px;margin: 5px 0 0;background: #303036;overflow: hidden;display: none;max-height: 200px;overflow-y: auto}
.customSelect_optionBox.active{display: block}
.customSelect_option{font-size:13px;display: block;color: rgba(255, 255, 255, 0.9);width: 100%;text-align: left;padding: 10px}
.customSelect_option.active{font-weight: 600}

.inputSubBox{display: flex;gap: 15px;align-items: flex-start;justify-content: space-between}
.inputSubBox .inputItemBox{width: calc(50% - 7.5px)}

.inputSubBoxOnly{margin: 24px 0 0}
.inputName.pageName{animation: none;transform: translate(0,0);position: static;color: var(--mainTextColor);font-size: 14px;font-weight: 700;opacity: 1}
.inputName.pageName + .inputItemBox{margin-top: 8px}

.inputViewBox{margin: 8px 0 0}
.inputViewText{border: 1px solid transparent;border-radius: 8px;width: 100%;padding: 13px 15px;font-size: 14px;color: var(--mainTextColor);background: #303036}
.inputViewBox.textAreaViewBox .inputViewText{display: block;resize: none;min-height: 180px;border-radius: 10px;padding: 12px 15px}
.fileViewBox{margin: 8px 0 0;width: 100vw;transform: translateX(-20px);padding: 0 20px;overflow-x: auto;display: flex;gap: 12px}
.fileViewBox>div{width: 70px;height: 70px;min-width: 70px;border-radius: 14px;position: relative}

/*footer*/
.footerMenu{background-color: #fff;z-index: 1000;position: fixed;bottom: 0;left: 0;width: 100%;height: 60px;border-top: 1px solid #F1F2F5;display: flex;align-items: center;justify-content: space-between;padding: 10px 28px;gap: 15px;}
.footerMenuItem{width: calc(25% - 11.25px);display: flex;align-items: center;justify-content: center;flex-direction: column;gap: 2px;font-size: 10px;font-weight: 500;}
.footerMenuItem.active{color: #4E98ED;}
.countItem{background: #FB4D44;border-radius: 9px;padding: 1px 4px 2px;white-space: nowrap;line-height: 1;color: #fff;font-size: 10px;font-weight: 700;position: absolute;top: 0;right: 0;}
.footerMenu_iconBox{position: relative}

/*floatingMenu*/
.floatingMenuBox{position: fixed;bottom: 18px;z-index: 1000;right: 20px;display: flex;flex-direction: column;align-items: flex-end;justify-content: flex-end;gap: 12px;}
.floatingMenuBox.footerChk{bottom: calc(61px + 18px);}
.floatingMenuItem{border-radius: 100%;min-width: 38px;min-height: 38px;display: flex;align-items: center;justify-content: center;}
.floatingMenuItem>img{max-width: 90%}

/*sadadream*/
.brandSection{padding-bottom: 20px;border-bottom: 1px solid rgba(229, 230, 235, 0.5)}
.brandBox{display: flex;align-items: flex-start;flex-wrap: wrap;justify-content: flex-start;padding: 20px;gap: 22px 15px}
.brandItem{width: calc(25% - 11.25px);color: #434446;text-align: center;font-size: 11px;}
.brandItem.active{font-weight: 700}
.brandItemImg{max-width: 100%;width: 65px;height: 65px;background: #F1F2F5;border-radius: 100%;overflow: hidden;margin: 0 auto 3px;display: flex;align-items: center;justify-content: center;font-weight: 700;}
.brandItemImg>img{width: 60%;}

.swiper-pagination{padding: 0;width: 100%;display: flex;align-items: center;justify-content: center;gap: 6px}
.swiper-pagination-bullet{background: rgba(33, 34, 35, 0.2);width: 5px;height: 5px;min-width: 5px;border-radius: 99px;transition: width 0.2s;}
.swiper-pagination-bullet-active{background: #4E98ED;width: 14px}

.listCategory_section{padding: 20px;display: flex;align-items: center;justify-content: space-between;gap: 15px;}
.listCategory_area{width: calc(100% - 52px - 15px);overflow-x: auto;display: flex;align-items: center;gap: 6px;}
.btn_categorySel{border-radius: 6px;border: 1px solid #E5E6EB;background: url(/assets/images/basic/sel.svg) no-repeat right 8px center;font-size: 12px;padding: 5px 21px 5px 8px;white-space: nowrap;}

.imgTapBox{display: flex;align-items: center;justify-content: flex-start;gap: 12px}
.imgTapItem{position: relative}
.imgTapItem + .imgTapItem::before{content: "";position: absolute;left: -6px;width: 1px;height: 14px;background: #D2D3D5;top: 50%;transform: translateY(-50%)}

.btnAddProduct{display: flex;align-items: center;justify-content: space-between;width: 100%;text-align: left;padding: 25px 0;gap: 15px;}
.btnAddProductTitle{font-size: 15px;font-weight: 700;color: #247bdf;}
.btnAddProductText{font-size: 13px;margin: 6px 0 0;}

.productCateSection{display: flex;align-items: flex-start;flex-wrap: wrap;justify-content: flex-start;padding: 20px 0;gap: 22px 15px}
.productCateItem{width: calc(25% - 11.25px);color: #77797c;text-align: center;font-size: 12px;}
.productCateItem.active{font-weight: 700}
.productCateItemImg{max-width: 100%;width: 52px;height: 52px;background: #F1F2F5;border-radius: 100%;overflow: hidden;margin: 0 auto 3px;display: flex;align-items: center;justify-content: center;font-weight: 700;}
.productCateItemImg>img{width: 40%;}
.productTypeItem{color: #434446;font-size: 13px;display: flex;align-items: center;gap: 10px;text-align: left;}
.productTypeItem + .productTypeItem{margin: 20px 0 0;}
.productTypeItem>span{line-height: 18px;}

.textSideLine{margin: 0 1px;width: 1px;height: 10px;background: #d2d3d5;display: inline-block;transform: translateY(1px);}

.productList{padding: 0 0 50px}
.sadadreamListItem{display: flex;gap: 14px;padding: 0 20px;}
.sadadreamListItem + .sadadreamListItem{margin: 16px 0 0;padding: 16px 20px 0;border-top: 1px solid #E5E6EB;}
.sadadreamListItem_img{width: 37%;min-width: 37%;padding: 0 0 37%;border-radius: 10px;overflow: hidden;position: relative;min-width: 90px;min-height: 90px;max-height: 90px;}
.sadadreamListItem_contetns{width: calc(63% - 14px);max-width: calc(100% - 90px - 14px);display: flex;flex-direction: column;justify-content: space-between;gap: 10px;}
.sadadreamListItem_info{width: 100%;text-align: left;}
.sadadreamListItem_date{display: flex;align-items: center;justify-content: flex-start;gap: 4px;color: #77797c;font-size: 11px;line-height: 1;margin: 0 0 4px;}
.sadadreamListItem_date>img{transform: translateY(-1px);}
.sadadreamListItem_name{font-size: 15px;font-weight: 500;}
.sadadreamListItem_priceBox{margin: 2px 0 0;display: flex;align-items: flex-end;gap: 0 6px;flex-wrap: wrap;}
.sadadreamListItem_price{color: #4E98ED;font-weight: 700;}
.sadadreamListItem_dreamFee{color: #77797C;font-size: 11px;}
.sadadreamListItem_market{margin: 4px 0 0;display: flex;align-items: center;gap: 4px;font-size: 12px;font-weight: 500;}
.sadadreamListItem_marketImg{min-width: 14px;height: 14px;border-radius: 3px;overflow: hidden;}
.sadadreamListItem_subInfoBox{display: flex;align-items: center;justify-content: flex-end;gap: 11px;flex-wrap: wrap;}
.sadadreamListItem_subInfo{display: flex;align-items: center;gap: 2px;color: #77797C;font-size: 12px;text-align: left}
.productTypeItem_cover{z-index: 2;color: rgba(33, 34, 35, 0.85);font-weight: 500;position: absolute;top: 0px;left: 0px;width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;font-size: 13px;backdrop-filter: blur(2px);background-color: rgba(255, 255, 255, 0.7);}
.productTypeItem_cover.dColor{background-color: rgba(0, 0, 0, 0.4);color: rgba(255, 255, 255, 0.85)}
.sadadreamListItem_label{backdrop-filter: blur(5px);background: rgba(78, 152, 237, 0.7);width: 100%;height: 30px;display: flex;align-items: center;justify-content: center;position: absolute;bottom: 0;left: 0;font-size: 12px;font-weight: 500;color: rgb(255, 255, 255);}
.sadadreamListItem_label.oColor{background: rgba(237, 111, 78, 0.7);}

.productList.col2{display: flex;flex-wrap: wrap;gap: 12px;padding: 0 20px 50px}
.sadadreamListItem.col2{padding: 0;border: 0;width: calc(50% - 6px);flex-direction: column;position: relative;}
.sadadreamListItem.col2 .sadadreamListItem_img{min-width: 0;max-width: 100%;max-height: none;min-height: 0;width: 100%;padding: 0 0 100%;}
.sadadreamListItem.col2 + .sadadreamListItem.col2{margin: 0;padding: 0;border: 0;}
.sadadreamListItem.col2 .sadadreamListItem_market{margin: 0 0 4px;}
.sadadreamListItem.col2 .sadadreamListItem_name{font-size: 13px;}
.sadadreamListItem.col2 .sadadreamListItem_priceBox{flex-direction: column;align-items: flex-start;gap: 2px;margin: 4px 0 0;}
.sadadreamListItem.col2 .sadadreamListItem_contetns{width:100%;max-width: 100%;}
.sadadreamListItem.col2 .sadadreamListItem_subInfo_v2{margin: 6px 0 0;display: flex;align-items: center;gap: 6px;}
.sadadreamListItem.col2 .sadadreamListItem_subInfo_v2_info{color: #77797C;font-size: 12px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;max-width: calc(100% - 12px - 6px);}
.sadadreamListItem_subInfo_v2_info span + span{padding: 0 0 0 10px;position: relative;}
.sadadreamListItem_subInfo_v2_info span + span::before{content: "";position: absolute;left: 5px;top: 50%;transform: translateY(-50%);width: 2px;height: 2px;display: block;border-radius: 100%;background: #77797C;}
.sadadreamListItem.col2 .sadadreamListItem_subInfo.likeItem{position: absolute;top: 10px;right: 10px;z-index: 10;width: 20px;height: 19px;}
.sadadreamListItem.col2 .sadadreamListItem_subInfo.likeItem>img{width: 100%;}
.sadadreamListItem.col2 .sadadreamListItem_subInfo.likeItem>span{text-indent: -9999px;}

.pc_section{max-width: 100%;padding: 0}
.pc_page,.pc_right_shadow{display: none}
#root{width:100%;min-width: 0;box-shadow: none}

@media (min-width:960px){
  body{background-color: #fafafa}
  
  .pc_section{max-width: 960px;width: 100%;margin: 0 auto;padding-left: 540px}
  .pc_cover{background-color: #fafafa;width: 100%;overflow-x: hidden}
  .pc_page{width: 541px;min-width: 540px;display: flex !important;flex-direction: column;align-items: center;justify-content: center;gap: 55px;padding: 20px;position: fixed;top: 0;right: calc(50% - 60px);height: 100%;z-index: 9999;background-color: #fafafa;overflow: hidden}
  .pc_page_title{text-align: center;color: #fff;font-size: 20px;font-weight: 700}
  .pcLogo{position: absolute;left: 20px;top: 20px;}
  .pc_btnBox{display: flex;align-items: center;justify-content: center;gap: 10px;margin: 20px 0 0;}
  .pc_titleArea{margin: 0 0 30px;}
  .pc_subText{font-size: 19px;text-align: center;margin: 30px 0 0;}
  .pc_titleArea{text-align: center;}
  .pc_titleCaption{font-size: 30px;font-weight: 700;color: #75b6f7;}
  .pc_title{font-size: 32px;font-weight: 700;color: #4E98ED;}
  .pc_titleBox{height: 44.8px;overflow: hidden;}
  .pc_titleItem{animation: pcText 12s infinite forwards}
  .pc_title + .pc_title{margin: 40px 0 0}

  .pc_right_shadow{display: block !important;width: 100vw;min-width: 100vw;background-color: #fafafa;overflow: hidden;position: fixed;top: 0;height: 100%;left: calc(50% + 479px);z-index: 9999}

  #root{width: 420px;min-width: 420px;background: #fff}

  .header{max-width: 420px;left: calc(50% + 60px)}
  .footerMenu{max-width: 420px;left: calc(50% + 60px)}
  .tapBox.headerComTap,.tapBox.fixedTap{max-width: 420px;left: calc(50% + 60px)}
  .pageBtn_box{max-width: 420px;left: calc(50% + 60px)}
  .sideMenu{max-width: 420px;left: calc(50% - 420px)}
  .sideMenu.active{left: calc(50% + 60px)}
  .bottomErrMsg{transform: translateX(40px)}
  .popup{max-width: 420px;left: calc(50% + 60px)}
  .popup .popupSetion{transform: translate(calc(-50% + 250px),-50%)}
  .toastPopup{max-width: 420px;left: calc(50% + 60px)}
  .rightPopup{max-width: 420px;right: inherit;left: calc(50% + 60px)}
  .headerTapBox{max-width: 420px;left: calc(50% + 60px)}
  .bgSectionCover{max-width: 420px;left: calc(50% + 60px)}
  .selPopup{max-width: 420px;left: calc(50% + 60px)}
  .rightPopup .pageBtn_box{left: 0}
  .pageSizing.fixedPage{max-width: 420px;left: calc(50% + 60px)}

  .loadingBox{max-width: 420px;left: calc(50% + 60px)}
  .floatingMenuBox{max-width: 38px;left: calc(50% + 60px + 420px - 38px - 20px)}
  .loadingBox .loadingItem{left: calc(50% + 60px + 210px)}

  .categoryBox{width: 420px}
  .inputFileList{width: 420px}
  .selPopup_selSub_category{width: 420px}
  .selPopup_selSub_lineItem{width: 420px}
  .subLine.pageFull{width: 420px}
  
  .toastPopup .bottomErrMsg{left: 0}
}

/*반응형*/
@media (max-width:1800px){

}
@media (max-width:1680px){
	
}
@media (max-width:1600px){

}
@media (max-width:1440px){

}
@media (max-width:1366px){

}
@media (max-width:1280px){

}
@media (max-width:1152px){

}
@media (max-width:1024px){

}
@media (max-width:960px){
    
}
@media (max-width: 720px){
    
}
@media (max-width: 650px){
    
}
@media (max-width: 550px){
}
@media (max-width: 490px){
}
@media (max-width: 400px){
}
@media (max-width: 340px){
}
/*//*/